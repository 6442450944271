<template>
  <!-- All starts from here -->
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap");
* {
  font-family: "Roboto", sans-serif !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden !important;
}

@media (max-width: 960px) {
  .el-dialog {
    width: 100% !important;
  }
}
@media (max-width: 1300px) and (min-width: 900px) {
  .el-drawer {
    width: 60% !important;
  }
}

@media (max-width: 960px) {
  .el-drawer {
    width: 100% !important;
  }
}
/* 
  .el-table td,
  .el-table {
    border-left: 2px solid green !important;
  } */

.el-table__expand-column {
  border-left: 5px solid #6770b9 !important;
}

.el-table tr {
  box-shadow: 0 7px 30px -10px rgba(150, 170, 180, 0.5);
  border-left: 1px solid #6770b9 !important ;
  background-origin: padding-box;
}

/* .el-table--border td {
    border-right: none;
    border-bottom: none;
  }
 
  .success {
    color: #43a784;
    font-size: 16px;
    background: #edfcf5;
    border: none;
  } */
</style>
