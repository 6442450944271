import { createRouter, createWebHistory } from "vue-router";
import Login from "./../Authentication/auth.vue";
import NotFoundComponent from "../views/NotFound.vue";

const routes = [
  {
    path: "",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: (to, from, next) => {
      const isLoggenIn = localStorage.getItem("Store-info");
      if (isLoggenIn === "true") {
        //check role and route here
        next("/home");
      } else {
        next();
      }
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/Profile.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/home",
    name: "home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/skills",
    name: "Skill Matrix",
    component: () =>
      import(
        /* webpackChunkName: "skill-matrix" */ "../views/SkillMatrixList.vue"
      ),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/add-skills",
    name: "Add Skill Matrix",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/SkillMatrix.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/view-project-documents",
    name: "View Project Documents",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/Documents.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/appraisal",
    name: "Self Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "skill-matrix" */ "../views/SelfEvaluation.vue"
      ),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/team-evaluation/:user_id?/:name?",
    name: "Team Evaluation",
    component: () =>
      import(
        /* webpackChunkName: "skill-matrix" */ "../views/TeamEvaluation.vue"
      ),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/team-members",
    name: "Team Members",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/TeamMembers.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/About.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/authorize/callback",
    name: "Authorize",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/Authorize.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/job-openings",
    name: "Job Openings",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/JobOpenings.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },
  {
    path: "/refer-candidate/:referralId/:role",
    name: "Refer Candidate",
    component: () =>
      import(
        /* webpackChunkName: "skill-matrix" */ "../views/ReferCandidate.vue"
      ),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },

  {
    path: "/resources",
    name: "Resources",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/Resources.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      const role = localStorage.getItem("role");
      token && role === "manager" ? next(true) : next("/login");
    },
  },

  {
    path: "/myreferrals",
    name: "MyReferrals",
    component: () =>
      import(/* webpackChunkName: "skill-matrix" */ "../views/MyReferrals.vue"),
    beforeEnter: (to, from, next) => {
      const token = localStorage.getItem("P2");
      token ? next(true) : next("/login");
    },
  },

  { path: "/:pathMatch(.*)", component: NotFoundComponent },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
});

export default router;
