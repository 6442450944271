import {
  ElDatePicker,
  ElTabs,
  ElTable,
  ElTag,
  ElTableColumn,
  ElTabPane,
  ElInput,
  ElSelect,
  ElOption,
  ElCheckbox,
  ElEmpty,
  ElNotification,
  ElForm,
  ElFormItem,
  ElRow,
  ElCol,
  ElDialog,
  ElButton,
  ElBadge,
  ElLoading,
  ElFooter,
  ElContainer,
  ElAside,
  ElDrawer,
  ElSkeleton,
  ElSkeletonItem,
  ElTooltip,
  ElCarousel,
  ElCarouselItem,
  ElCard,
  ElDivider,
  ElMessageBox,
  ElRate,
  ElTimePicker,
  ElPageHeader,
  ElPopover,
  ElIcon,
  ElPagination,
  ElDescriptions,
  ElDescriptionsItem,
  ElAvatar,
  ElMenu,
  ElMenuItem,
  ElSubmenu,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElImage,
  ElPopconfirm,
  ElSlider,
  ElUpload,
  ElRadioGroup,
  ElRadio,
} from "element-plus";

export default (app) => {
  app.use(ElDatePicker);
  app.use(ElTabs);
  app.use(ElTabPane);
  app.use(ElTag);
  app.use(ElInput);
  app.use(ElSelect);
  app.use(ElOption);
  app.use(ElCheckbox);
  app.use(ElEmpty);
  app.use(ElNotification);
  app.use(ElForm);
  app.use(ElFormItem);
  app.use(ElRow);
  app.use(ElCol);
  app.use(ElDialog);
  app.use(ElButton);
  app.use(ElBadge);
  app.use(ElLoading);
  app.use(ElFooter);
  app.use(ElContainer);
  app.use(ElAside);
  app.use(ElDrawer);
  app.use(ElSkeleton);
  app.use(ElSkeletonItem);
  app.use(ElTooltip);
  app.use(ElTable);
  app.use(ElTableColumn);
  app.use(ElCarousel);
  app.use(ElCarouselItem);
  app.use(ElCard);
  app.use(ElDivider);
  app.use(ElMessageBox);
  app.use(ElRate);
  app.use(ElTimePicker);
  app.use(ElPageHeader);
  app.use(ElPopover);
  app.use(ElIcon);
  app.use(ElPagination);
  app.use(ElDescriptions);
  app.use(ElDescriptionsItem);
  app.use(ElAvatar);
  app.use(ElMenu);
  app.use(ElMenuItem);
  app.use(ElSubmenu);
  app.use(ElDropdown);
  app.use(ElDropdownMenu);
  app.use(ElDropdownItem);
  app.use(ElImage);
  app.use(ElPopconfirm);
  app.use(ElSlider);
  app.use(ElUpload);
  app.use(ElRadioGroup);
  app.use(ElRadio);
};
