<template>
  <section>
    <div class="notFound">
      <span
        ><img src="../assets/images/page_not_found.svg" class="not-found-img" />
        <br />
      </span>
      <h1 class="sub-text">
        Page Not Found
      </h1>

      <el-button
        type="primary"
        @click="$router.go(-1)"
        icon="el-icon-arrow-left"
        >Back to home</el-button
      >
    </div>
  </section>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  padding: 10px;
  text-align: center;

  .sub-text {
    color: #2f2e41;
    font-size: 30px;

    @media (max-width: 960px) {
      font-size: 15px;
    }
  }
  .not-found-img {
    width: 50%;

    @media (max-width: 960px) {
      width: 100%;
    }
  }
}
</style>
