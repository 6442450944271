import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
// import './element-variables.scss';
import GAuth from 'vue3-google-oauth2';
import "./assets/theme-chalk/index.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import installElementPlus from "./plugins/element";
import store from "./store";

// import '../node_modules/bootstrap/dist/css/bootstrap.min.css'

library.add(fas);
const gAuthOptions = { clientId: '325287824188-htf7gip97tvha4tdn90ov0jm98r6fclp.apps.googleusercontent.com', scope: 'email', prompt: 'consent', fetch_basic_profile: true }
createApp(App)
  .use(store)
  .use(GAuth, gAuthOptions)
  .component("fa", FontAwesomeIcon)
  .use(router)
  .use(installElementPlus)
  .mount("#app");
