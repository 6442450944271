<template>
  <el-form
    v-loading.fullscreen.lock="fullscreenLoading"
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    action=""
    class="login-form"
    v-if="active"
    @submit="
      $event.preventDefault();
      __signin($event);
    "
  >
    <div class="place-between pace-icon">
      <div>
        <h2>Login</h2>
        <p>Welcome Back To Timesheet Portal</p>
      </div>
      <img src="@/assets/images/pacewisdom.jpg" alt="pacewisdom" width="70" />
    </div>

    <!-- Email Id -->
    <div class="input-group">
      <label>Email ID</label>
      <el-form-item prop="email">
        <el-input placeholder="Enter Email" v-model="ruleForm.email"></el-input>
      </el-form-item>
    </div>

    <!-- password -->
    <div class="input-group">
      <label>Password</label>
      <el-form-item prop="password">
        <el-input
          placeholder="Enter password"
          v-model="ruleForm.password"
          show-password
        ></el-input>
      </el-form-item>
    </div>

    <!-- submit btn -->
    <div class="btn-group">
      <button type="submit" class="btn primary-bg color-white">Sign in</button>
    </div>
  </el-form>
</template>

<script>
import login from "../../services/login.js";
export default {
  name: "passwordAuth",
  data() {
    return {
      fullscreenLoading: false,
      active: true,
      ruleForm: {
        email: "",
        password: ""
      },

      //validations
      rules: {
        email: [
          {
            required: true,
            message: "Please Input Email Address",
            trigger: "blur"
          },
          {
            type: "email",
            message: "Please Enter Valid Email Address",
            trigger: "blur"
          }
        ],
        password: [
          {
            required: true,
            message: "Please Input Password",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' }
        ]
      }
    };
  },

  methods: {
    /**
     * @description user login data validation - final submit
     */
    __signin(ev) {
      // this.active = false;
      this.fullscreenLoading = true;

      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          let data = {
            email: this.ruleForm.email,
            password: this.ruleForm.password
          };
          // call api service
          login
            .authenticate(data)
            .then(res => {
              console.log(ev);
              //  this.$emit("login", false);
              localStorage.setItem("P2", res.data.access);
              this.$notify({
                title: "Success",
                message: "Login Success",
                type: "success",
                position: "bottom-right"
              });
              this.fullscreenLoading = false;
              this.$router.push({ path: "home" });
              localStorage.setItem("Store-info", true);
            })
            .catch(err => {
              console.log("error from error", err);
              // this.$emit("login", false);
              this.fullscreenLoading = false;

              this.$router.push({ path: "Login" });
            });
        } else {
          return;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.login-form {
  padding: 20px;
  .pace-icon {
    align-items: baseline;
  }
}
</style>
