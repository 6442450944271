import { createStore } from "vuex";
import moment from "moment";

export default createStore({
  // "2021-06-19"
  state: {
    numbers: moment().format("YYYY-MM-DD"),
    counter: 0,
    total_hours: 0
  },
  mutations: {
    ADD_NUMBER(state, payload) {
      state.numbers = payload;
    },
    ADD_COUNTER(state, payload) {
      console.log(payload);
      state.counter++;
    },
    ADD_HOURS(state, payload) {
      console.log(payload);
      state.total_hours++;
    }
  },
  getters: {
    getNumbers(state) {
      return state.numbers;
    },
    getCounter(state) {
      return state.counter;
    },
    getHours(state) {
      return state.total_hours;
    }
  },

  actions: {
    addNumber(context, number) {
      context.commit("ADD_NUMBER", number);
    },
    addCounter(context, counter) {
      context.commit("ADD_COUNTER", counter);
    },
    addHours(context, counter) {
      context.commit("ADD_HOURS", counter);
    }
  },
  modules: {}
});
