<template>
  <section class="login-section">
    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__bounce"
      leave-active-class="animate__animated animate__zoomOut animate__slow"
    >
      <div class="pacewisdom-cover" v-if="login">
        <img src="@/assets/images/logo.png" alt="pacewisdom" width="250" />
      </div>
    </transition>

    <transition
      name="custom-classes-transition"
      enter-active-class="animate__animated animate__bounce"
      leave-active-class="animate__animated animate__zoomOut animate__slow"
    >
      <div class="login-container" v-if="login">
        <passwordAuth @login="__Islogin($event)" />
        <GoogleAuth />
      </div>
    </transition>
  </section>
</template>

<script>
import passwordAuth from "./Auth/password.auth.vue";
import GoogleAuth from "./Auth/google.auth.vue";

export default {
  name: "login",
  components: {
    passwordAuth,
    GoogleAuth
  },
  data() {
    return {
      login: true
    };
  },
  methods: {
    __Islogin(ev) {
      this.login = ev;

      // setTimeout(() => {
      //   this.$router.push({ path: "home" });
      // }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.login-section {
  width: 100vw;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .pacewisdom-cover {
    @media (max-width: 720px) {
      display: none;

      .login-container {
        border: none;
      }
    }
  }
}

.login-container {
  width: 400px;
  background-color: #fff;
  border: 1px solid rgb(156, 177, 199);
  border-radius: 16px;
  padding: 20px;

  @media (max-width: 720px) {
    border: none;
  }
}
</style>
