<template>
  <section class="google-auth" v-loading.fullscreen.lock="fullscreenLoading">
    <div class="or-option"><span>OR</span></div>

    <div
      class="btn-group"
      @click="handleClickSignIn"
      v-if="!isSignIn"
      :disabled="!isInit"
    >
      <button class="btn color-black place-center">
        <img
          class="google-icon"
          src="@/assets/images/search.svg"
          alt="google"
          width="20"
        />
        <span style="text-decoration: none">Sign in with Google</span>
      </button>
    </div>
  </section>
</template>

<script>
export default {
  name: "GoogleAuth",
  data() {
    return {
      isInit: false,
      isSignIn: false,
      fullscreenLoading: false
    };
  },
  methods: {
    // async handleClickGetAuth() {
    //   try {
    //     const authCode = await this.$gAuth.getAuthCode();
    //     const response = await this.$http.post(
    //       "http://your-backend-server.com/auth/google",
    //       { code: authCode, redirect_uri: "postmessage" }
    //     );
    //     console.log(response);
    //   } catch (error) {
    //     // On fail do something
    //     console.log(error);
    //   }
    // },

    async handleClickSignIn() {
      try {
        const googleUser = await this.$gAuth.signIn();
        this.isSignIn = this.$gAuth.isAuthorized;
        const access_token = await googleUser.getAuthResponse(true)
          .access_token;
        this.fullscreenLoading = true;
        //  console.log("s", access_token);
        const data = {
          access_token: access_token
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data)
        };
        const response = await fetch(
          "https://projects.pacewisdom.in/timesheet/api/dj-rest-auth/google/",
          requestOptions
        );
        const jsonResponse = await response.json();
        localStorage.setItem("P2", jsonResponse.access_token);
        this.$notify({
          title: "Success",
          message: "Login Success",
          type: "success",
          position: "bottom-right"
        });
        this.$router.push({ path: "home" });
        localStorage.setItem("Store-info", true);
      } catch (error) {
        // On fail do something
        console.error("error", error);
        return null;
      }
    }
  },
  mounted() {
    let that = this;
    let checkGauthLoad = setInterval(function() {
      that.isInit = that.$gAuth.isInit;
      that.isSignIn = that.$gAuth.isAuthorized;
      if (that.isInit) clearInterval(checkGauthLoad);
    }, 1000);
  }
};
</script>

<style lang="scss" scoped>
.google-auth {
  padding: 20px;
  text-decoration: none;
  .or-option {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eee;
    line-height: 0.1em;
    margin: 0px 0 30px;

    span {
      background: #fff;
      padding: 0 10px;
    }
  }

  .google-icon {
    padding: 0px 10px;
  }
}
</style>
